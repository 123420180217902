/**
 * Back to top
 */

/**
 * Add body class .has-no-hero-background
 */
const annimateBackToTop = () => {
  if (document.querySelector(".backtotop")) {
    var backtotop = document.querySelector(".backtotop");
    backtotop.onclick = function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
  }
};
annimateBackToTop();
