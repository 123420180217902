/**
 * Init Utils
 */

/**
 * Add identifiers for first and last block
 */
const initUtils = () => {
	// if (
	// 	!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
	// 		navigator.userAgent
	// 	)
	// ) {
	// 	document.body.classList.add('is_pc')
	// } else {
	// 	document.body.classList.add('is_mobile')
	// }

	setTimeout(function () {
		minHeight()
	}, 50)
	setTimeout(function () {
		minHeight()
	}, 500)
	window.addEventListener('resize', function () {
		minHeight()
	})

	function minHeight() {
		const siteContent = document.querySelector('.site-content')
		const siteHeader = document.querySelector('.site-header')
		const siteBreadcrumbs = document.querySelector('.site-breadcrumbs')
		const siteFooter = document.querySelector('.site-footer')

		if (!!siteContent) {
			let siteHeight = parseInt(window.innerHeight, 10)

			if (!!siteHeader) {
				siteHeight -= parseInt(siteHeader.offsetHeight, 10)
			}

			if (!!siteBreadcrumbs) {
				siteHeight -= parseInt(siteBreadcrumbs.offsetHeight, 10)
			}

			if (!!siteFooter) {
				siteHeight -= parseInt(siteFooter.offsetHeight, 10)
			}

			siteContent.style.minHeight = siteHeight + 'px'
		}
	}

	// import ResponsiveAutoHeight from 'responsive-auto-height';
	const autoheight = document.querySelector('.has-equalheight')
	if (autoheight) {
		new ResponsiveAutoHeight('.has-equalheight')
	}
}
initUtils()
