document.addEventListener('DOMContentLoaded', function () {
	var sliderClass = document.querySelectorAll('.slider .splide')
	if (sliderClass.length > 0) {
		for (var i = 0; i < sliderClass.length; i++) {
			new Splide(sliderClass[i], {
				type: 'loop',
			}).mount()
		}
	}
})
