/**
 * Init Utils
 */

/**
 * Add identifiers for first and last block
 */
const menuEffect = () => {
	const siteHeader = document.querySelector('.site-header')
	if (!siteHeader) return
	const siteHeaderOffsetTop = siteHeader.offsetTop

	const siteMenuPlaceholder = document.querySelector('.site-header-placeholder')
	if (!siteMenuPlaceholder) return

	let scrollTopBefore = 0

	window.addEventListener('scroll', function () {
		const scrollTop = Math.abs(
			parseInt(document.documentElement.getBoundingClientRect().top)
		)
		// console.log('scrollTop : ' + scrollTop)

		if (
			scrollTop <= siteHeaderOffsetTop + 1 ||
			scrollTop <= siteMenuPlaceholder.getBoundingClientRect().top + 1
		) {
			return (
				siteHeader.classList.remove('is-scrolled'),
				siteHeader.classList.remove('is-fixed'),
				siteHeader.classList.remove('is-transition')
			)
		}
		if (scrollTop > siteHeaderOffsetTop + 2 * siteHeader.offsetHeight) {
			siteHeader.classList.add('is-scrolled')
			setTimeout(function () {
				siteHeader.classList.add('is-transition')
			}, 300)
		}

		if (siteHeader.classList.contains('is-transition')) {
			if (Math.abs(scrollTop - scrollTopBefore) < 5) {
				if (scrollTop < scrollTopBefore) {
					siteHeader.classList.add('is-fixed')
				} else {
					siteHeader.classList.remove('is-fixed')
				}
			}
			scrollTopBefore = scrollTop
		}
	})
}
menuEffect()
