/**
 * tabs
 */
const tabs = () => {
	if (document.querySelector('.tabs')) {
		function selects() {}
		document.querySelectorAll('.tabs__titles .tabs__title').forEach((item) => {
			item.addEventListener('click', (event) => {
				// console.log(item)
				var ele = document.querySelectorAll(
					'.tabs__titles .tabs__title, .tabs__contenus .tabs__contenu'
				)
				for (var i = 0; i < ele.length; i++) {
					ele[i].classList.remove('open')
				}

				// var ele = document.querySelectorAll('.tabs__contenus .tabs__contenu')
				// for (var i = 0; i < ele.length; i++) {
				// 	ele[i].classList.remove('open')
				// }
				//this function is executed after function1
				item.classList.add('open')

				if (item.dataset.tabs) {
					const el2 = document.querySelector(
						'.tabs__contenus [data-tabs="' + item.dataset.tabs + '"]'
					)
					// console.log(el2)
					el2.classList.add('open')
				}
			})
		})
	}
}
tabs()
