/* ==========================================================================
  main.js
========================================================================== */

'use strict'
/* global fitvids, cssVars */

window.onload = function () {
	document.body.classList.add('loaded')
}

/**
 * Init responsive videos
 */
fitvids()
